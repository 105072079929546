import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./multi.css";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import Button from "@mui/material/Button";
import CustomModal from "../modal/CustomModal";

const projects = {
  0: [
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678761/textura/liveImages/Portfolio/project1/lihmgw6kiexboqtxqwnt.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678846/textura/liveImages/Portfolio/project1/f44zawxdjl44vz6j2etp.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678778/textura/liveImages/Portfolio/project1/dhxmjqqkz4st7rggtgkg.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678855/textura/liveImages/Portfolio/project1/zryxf3xb8kvn1flb4yly.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678755/textura/liveImages/Portfolio/project1/qhlppmpdb74ly4fwxgkx.png",
  ],
  1: [
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678875/textura/liveImages/Portfolio/project2/rdfuo4purzciwazqvfee.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678882/textura/liveImages/Portfolio/project2/rmifcnrjtrekknw5nrki.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678858/textura/liveImages/Portfolio/project2/hfnz4l3r1yzibrx2ugbm.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678874/textura/liveImages/Portfolio/project2/rpgyaytfc8rsuv2j4g4s.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678869/textura/liveImages/Portfolio/project2/qfpwzuzawiyyyvnybsal.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678864/textura/liveImages/Portfolio/project2/dmmyykcogsyktdo1j23f.png ",
  ],
  2: [
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678708/textura/liveImages/Portfolio/project3/dvkfa44cibctvyp4rxzy.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678710/textura/liveImages/Portfolio/project3/dszndfu1pujrk5f5vowz.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678706/textura/liveImages/Portfolio/project3/yul8uuachosf2j7xzpt5.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678707/textura/liveImages/Portfolio/project3/jrrfbplp7uvqk56r8868.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678708/textura/liveImages/Portfolio/project3/pxbkdnvgafchdzdhpesj.jpg ",
  ],
  3: [
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678711/textura/liveImages/Portfolio/project4/un31jcsdinmrsksvzwhj.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678718/textura/liveImages/Portfolio/project4/n3jiclz7hktzpforg9wu.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678712/textura/liveImages/Portfolio/project4/vdu1ymw8cyhzbnsfronz.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678718/textura/liveImages/Portfolio/project4/ic7yh4g6cqifpdrytvzb.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678712/textura/liveImages/Portfolio/project4/xc3tfsc5nt859v76xm8c.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678707/textura/liveImages/Portfolio/project4/elv42dea3z9ydjmwqhjm.jpg",
  ],
  4: [
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678736/textura/liveImages/Portfolio/project5/lghaddyhip6qz4ajwyhl.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678780/textura/liveImages/Portfolio/project5/jew6wstlyjpljkefbhdv.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678783/textura/liveImages/Portfolio/project5/jlefpqn2bouj2opzuyyc.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678725/textura/liveImages/Portfolio/project5/kd5ewwxbiukd5fhirvmh.jpg",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678719/textura/liveImages/Portfolio/project5/altqov2xio0zgetvgofm.jpg",
  ],
  5: [
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678726/textura/liveImages/Portfolio/project6/jity0v36lab2dtx8edth.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678719/textura/liveImages/Portfolio/project6/odigbjlzgpsxxkuk1oss.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678722/textura/liveImages/Portfolio/project6/bqvw8r2mypbw5ghmrrjk.png",
  ],
  6: [
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678752/textura/liveImages/Portfolio/project7/b18t3mnvq0v6ujhmffmu.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678779/textura/liveImages/Portfolio/project7/gefzzwevqgahppkgx1nj.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678793/textura/liveImages/Portfolio/project7/soo43emhhjthrzargnwc.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678774/textura/liveImages/Portfolio/project7/pmp77l0whkquv6r6ggmd.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678815/textura/liveImages/Portfolio/project7/ggxgvmdi1jm27ldcmzru.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678794/textura/liveImages/Portfolio/project7/ovquojwye9qewnqb5sme.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678774/textura/liveImages/Portfolio/project7/z2vn9re5pfl1hntm5wqf.png",
  ],
  7: [
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678718/textura/liveImages/Portfolio/project8/px5jhgrud7hzncmikuke.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678713/textura/liveImages/Portfolio/project8/gev8bvuyhumigfkbz5zq.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678714/textura/liveImages/Portfolio/project8/z825coctk1orbmignvgt.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678719/textura/liveImages/Portfolio/project8/v4lvme8grdy0cx1xuii4.png",
    "https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678713/textura/liveImages/Portfolio/project8/cb0o3l9pvkfxyr1whdzy.png",
  ],
};

 
const CollectiveImages = [
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678732/textura/liveImages/Portfolio/collective/prz9hz6zwzaaamolnmc5.png',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678744/textura/liveImages/Portfolio/collective/ffz4bbniuip4mcbt9d9l.png',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678724/textura/liveImages/Portfolio/collective/syreli1tnzbrcxaqacsm.png',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678723/textura/liveImages/Portfolio/collective/vvpgz80tsni18ytwyd3e.png',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678735/textura/liveImages/Portfolio/collective/qcocs1fglwefib8xc4yi.png',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678752/textura/liveImages/Portfolio/collective/xx420wkvnnpb4f1zxuaw.png',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678725/textura/liveImages/Portfolio/collective/fs0vgadhniypi0yfbvqp.png',

]


export default function MultiSlide() {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(null);
  };

  return (
    <div className="portfolio-multi">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Autoplay, Navigation]}
        className="mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {CollectiveImages.map((content, index) => {
          return (
            <SwiperSlide key={index}>
              <Button
                onClick={() => handleOpen(index)}
                style={{ color: "white" }}
              >
                <div className="overlay-text">
                  View More<span className="rotate45"> → </span>
                </div>
              </Button>
              <img
                src={content}
                alt={`Project ${index + 1}`}
              />
            </SwiperSlide>
          );
        })}
        <CustomModal
          open={open}
          onClose={handleClose}
          currentIndex={currentIndex}
          projects={projects}
        />
      </Swiper>
    </div>
  );
}
