import styled from "styled-components";
import MultiSlide from "../carousel/MultiSlide";

const PortfolioHeading = styled.h1`
  color: #663c2a;
  margin-top: 10px;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`;
const PortfolioSubHeading = styled.p`
  color: #00000;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const SectionWrapper = styled.div`
  position: relative;
  text-align:center;
  padding: 50px 0;
  background-color: #C1B59F;
  overflow: hidden;
`;


export default function Portfolio() {
  return (
    <SectionWrapper id='portfolioTextura'>
      <PortfolioSubHeading>Best Works</PortfolioSubHeading>
      <PortfolioHeading>Our Portfolio</PortfolioHeading>
      <div>
        <MultiSlide />
      </div>
    </SectionWrapper>
  );
}
