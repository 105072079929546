import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Services from "./components/services/Services";
import CounterSection from "./components/counter/CounterSection";
import TestimonialSection from "./components/testimonial/Testimonial";
import styled from "styled-components";
import { useEffect, useRef } from "react";
import Footer from "./components/footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import SwiperCarousel from "./components/swiperCarousel/swiperCarousel";
import Portfolio from "./components/portfolio/portfolio";
import PortfolioThree from "./components/3dportfolio/portfolioThree";
import LiveSite from "./components/portfolio/liveSite";
const FollowCursor = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid black;
  pointer-events: none;
  z-index: 9999;
  transition: transform 0.2s ease;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
  }
`;

function App() {
  const cursorRef = useRef(null);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  useEffect(() => {
    const updateCursorPosition = (event) => {
      const cursor = cursorRef.current;
      if (cursor) {
        cursor.style.transform = `translate(${event.clientX - 15}px, ${
          event.clientY - 15
        }px)`;
      }
    };

    const handleMouseMove = (event) => {
      requestAnimationFrame(() => {
        updateCursorPosition(event);
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <FollowCursor ref={cursorRef} />
      <Navbar />
      <SwiperCarousel />
      <About />
      <LiveSite />
      <Services />
      <Portfolio />
      <CounterSection />
      <PortfolioThree />
      <TestimonialSection />
      <Footer />
    </>
  );
}

export default App;
