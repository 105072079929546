import React from "react";
import AboutImg from "../../images/about.jpg";
import { Grid } from "@mui/material";
import {
  AboutContentWrapper,
  SectionHeading,
  SectionInnerHeading,
  SectionWrapper,
  StyledAboutImage,
  StyledButton,
  StyledPara,
} from "./about.styles";

const About = () => {
  return (
    <SectionWrapper id='aboutTextura'>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} data-aos="fade-right" >
          <StyledAboutImage src={AboutImg} alt="About me" />
        </Grid>

        <Grid item xs={12} md={6} data-aos="fade-left">
          <AboutContentWrapper >
            <SectionHeading>About Us</SectionHeading>
            <SectionInnerHeading>
              Creative Solutions By Professional Designers
            </SectionInnerHeading>
            <StyledPara>
              At Textura, we believe that every space tells a story. 
              Our team of passionate designers is dedicated to transforming your 
              vision into reality, creating interiors that are not only beautiful 
              but also functional. With a keen eye for detail and a commitment to 
              quality, we work closely with our clients to understand their needs 
              and preferences, ensuring that each project reflects their unique 
              style. Whether it's a cozy home, a vibrant office, or a chic retail 
              space, we bring creativity and expertise to every design. Let us 
              help you create a space that inspires and delights.
            </StyledPara>

            {/* <StyledButton>Continue Reading</StyledButton> */}
          </AboutContentWrapper>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default About;
