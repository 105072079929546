import styled from "styled-components";
import Card from "@mui/material/Card";

export const StyledServiceContentHeading = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
`;

export const SectionWrapper = styled.div`
  padding: 6%;
  background:#e4e4dc;
`;
export const StyledServiceCard = styled(Card)`
  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    filter:none;
  }
    border-radius:unset !important;
    filter:grayscale(1);
    @media (max-width: 768px) {
      filter:none;
    }

`;
