import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';
import './styles.css';

// import required modules
import { EffectCreative, Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function SwiperCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        grabCursor={true}
        speed={900}
         autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        effect={'creative'}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex); // Capture the active slide index
        }}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: ['-20%', 0, -1],
          },
          next: {
            translate: ['100%', 0, 0],
          },
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, EffectCreative, Navigation]}
        className="mySwiper3"
      >
        <SwiperSlide>
          <div className={`overlay-swiper ${activeIndex === 0 ? 'active-slide' : ''}`}></div>
          <div className={`content ${activeIndex === 0 ? 'active-slide' : ''}`}>
            <h1>Modern Elegance</h1>
            <h2>Refined Spaces</h2>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={`overlay-swiper ${activeIndex === 1 ? 'active-slide' : ''}`}></div>
          <div className={`content ${activeIndex === 1 ? 'active-slide' : ''}`}>
            <h1>Timeless Design</h1>
            <h2>Classic Aesthetics</h2>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={`overlay-swiper ${activeIndex === 2 ? 'active-slide' : ''}`}></div>
          <div className={`content ${activeIndex === 2 ? 'active-slide' : ''}`}>
            <h1>Artful Living</h1>
            <h2>Inspired Interiors</h2>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={`overlay-swiper ${activeIndex === 3 ? 'active-slide' : ''}`}></div>
          <div className={`content ${activeIndex === 3 ? 'active-slide' : ''}`}>
            <h1>Bold Statements</h1>
            <h2>Innovative Designs</h2>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
