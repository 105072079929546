import React from "react";
import "./footer.css"; // Import CSS for styling

const Footer = () => {
  return (
    <footer className="footer" id="footerTextura">
      <div className="footer-container">
        {/* Logo and Social Media */}
        <div className="footer-section logo-section">
          <img src="https://res.cloudinary.com/dk7vwmblr/image/upload/v1730189204/textura/ygn5nzolzbazvzq5fqtw.jpg" alt="Textura" className="footer-logo" />
          <p>Because Quality Matters!</p>
          <div className="social-icons">
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/textura_interior_/" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="#">
              <i className="fab fa-pinterest"></i>
            </a>
            <a href="https://wa.me/919619493232" target="_blank">
              <i className="fab fa-whatsapp"></i>
            </a>
          </div>
        </div>

        {/* Quick Links */}
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li>
              {" "}
              <a href="#aboutTextura"> About us</a>
            </li>
            <li>
              <a href="#portfolioTextura">Projects</a>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="footer-section">
          <h3>Contact Us</h3>
          <p>+91 9619493232</p>
          <p>
            <a href="mailto:pratikvbaria98@textura.co.in" className="footer-link">
              pratikvbaria98@textura.co.in
            </a>
          </p>
        </div>

       
      </div>

   
    </footer>
  );
};

export default Footer;
