import styled from "styled-components";
import Card from "@mui/material/Card";

export const SectionWrapper = styled.div`
  padding: 6%;
`;

export const StyledAboutImage = styled.img`
  width: 100%;
  max-height: 500px;
  object-fit:contain;
`;

export const AboutContentWrapper = styled.div`
  padding: 20px;
`;

export const SectionHeading = styled.div`
  font-family: "Playfair Display", serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: ${({ center }) => center && "center"};
`;

export const SectionInnerHeading = styled.div`
  font-family: "Playfair Display", serif;
  color: #663c2a;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: ${({ marginBottom }) => marginBottom + `px`};
  text-align: ${({ center }) => center && "center"};
  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

export const StyledPara = styled.p`
  font-family: "Playfair Display", serif;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const StyledButton = styled.button`
  outline: none;
  border: none;
  background-color: #663c2a;
  padding: 14px;
  font-family: "Playfair Display", serif;
  color: white;
  border: 2px solid #663c2a;

  &:hover {
    background-color: white;
    border: 2px solid blue;
    color: blue;
  }
`;

export const StyledServiceCard = styled(Card)`
  &:hover {
    cursor: pointer;
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  }
`;
