import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import { ContentWrapper, CounterWrapper, IconWrapper, StyledCounterHeading, StyledCounterValue } from "./counterStyles.js";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import HandshakeIcon from "@mui/icons-material/Handshake";
import SquareFootIcon from "@mui/icons-material/SquareFoot";

const CounterSection = () => {
  const [counters] = useState([
    { label: "Projects Completed", value: 100, icon: <BedroomParentIcon  /> },
    { label: "Satisfied Clients", value: 500, icon: <HandshakeIcon  /> },
    { label: "Square Feet", value: 100000, icon: <SquareFootIcon  /> },
  ]);

  // Hook to detect if the section is in view
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation will only trigger once
    threshold: 0.1,    // Trigger when 10% of the section is in view
  });

  return (
    <CounterWrapper ref={ref} data-aos="fade-up" data-aos-duration="100">
      <Grid container spacing={3} justifyContent="center">
        {counters.map((counter, index) => (
          <Grid item key={index} xs={3} sm={6} md={4}>
            <ContentWrapper>
              <IconWrapper>{counter.icon}</IconWrapper>
              <StyledCounterValue>
                {inView ? <CountUp start={0} end={counter.value} duration={2.75} /> : 0}
              </StyledCounterValue>
              <StyledCounterHeading>{counter.label}</StyledCounterHeading>
            </ContentWrapper>
          </Grid>
        ))}
      </Grid>
    </CounterWrapper>
  );
};

export default CounterSection;
