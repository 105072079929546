import styled, { css } from "styled-components";
import { Typography as MuiTypography } from "@mui/material";

export const TransparentNavbar = styled.div`
  ${({ scrolling }) =>
    scrolling
      ? css`
          background-color: rgba(255, 255, 255, 0.8);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 999;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `
      : css`
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
        `}
`;
export const HamburgerIcon = styled.div`
  display: none;
  font-size: 24px;
  cursor: pointer;
  padding: 2%;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    font-family: "Playfair Display", serif;
  }
`;

export const MobileMenuItem = styled.a`
  padding: 15px;
  color: #000;
  cursor: pointer;
  text-align: center;
  border-bottom: 1px solid #555;
  text-decoration: none;
`;

export const MobileSocialIcons = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;

  @media (max-width: 768px) {
  }
`;

export const StyledTypography = styled(MuiTypography)`
  color: #333;
  font-size: 40px !important;
  font-family: "Playfair Display", serif;
`;

export const NavWrapper = styled.div`
  background-color: transparent;
  align-items: center;
  justify-content: space-between;
`;

export const LogoText = styled.a`
  font-size: 40px;
  font-weight: 600;
  font-family: "Playfair Display", serif;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledUlWrapper = styled.ul`
  list-style: none;
  display: flex;
  padding: 0px;
  margin: 0px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledLiWrapper = styled.li`
  margin: 20px;
  letter-spacing: 1px;
  font-family: "Playfair Display", serif;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
    transition: text-decoration 0.1s ease-in-out;
  }
`;

export const MainIconWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  padding: 15px;

  &:hover {
    cursor: pointer;
  }
`;


export const LogoImage = styled.img`
  height:70px;
  object-fit:contain;
`