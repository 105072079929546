import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./multi.css";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import Button from "@mui/material/Button";

const projects = {
  0: [
    "/Portfolio/project1/image0.png",
    "/Portfolio/project1/image1.png",
    "/Portfolio/project1/image2.png",
    "/Portfolio/project1/image3.png",
    "/Portfolio/project1/image4.png",
  ],
  1: [
    "/Portfolio/project2/image0.png",
    "/Portfolio/project2/image1.png",
    "/Portfolio/project2/image2.png",
    "/Portfolio/project2/image3.png",
    "/Portfolio/project2/image4.png",
    "/Portfolio/project2/image5.png",
  ],
  2: [
    "/Portfolio/project3/p31.jpg",
    "/Portfolio/project3/p32.jpg",
    "/Portfolio/project3/p33.jpg",
    "/Portfolio/project3/p35.jpg",
    "/Portfolio/project3/p37.jpg",
  ],
  3: [
    "/Portfolio/project4/q25.jpg",
    "/Portfolio/project4/q26.jpg",
    "/Portfolio/project4/q27.jpg",
    "/Portfolio/project4/q28.jpg",
    "/Portfolio/project4/q29.jpg",
    "/Portfolio/project4/q30.jpg",
  ],
  4: [
    "/Portfolio/project5/r39.jpg",
    "/Portfolio/project5/r40.jpg",
    "/Portfolio/project5/r46.jpg",
    "/Portfolio/project5/r48.jpg",
    "/Portfolio/project5/r49.png",
  ],
  5: [
    "/Portfolio/project6/image2.png",
    "/Portfolio/project6/image3.png",
    "/Portfolio/project6/image4.png",
  ],
  6: [
    "/Portfolio/project7/image1.png",
    "/Portfolio/project7/image2.png",
    "/Portfolio/project7/image3.png",
    "/Portfolio/project7/image4.png",
    "/Portfolio/project7/image5.png",
    "/Portfolio/project7/image6.png",
    "/Portfolio/project7/image7.png",
  ],
  7: [
    "/Portfolio/project8/image0.png",
    "/Portfolio/project8/image1.png",
    "/Portfolio/project8/image2.png",
    "/Portfolio/project8/image3.png",
    "/Portfolio/project8/image4.png",
  ],
};

const CollectiveImages = [
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678461/textura/d95lyltlbgbe4hpn6ip5.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678468/textura/op6mj37aynthrv9wlctk.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678463/textura/mpmc5twsffvbnnsd04nw.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678468/textura/b6lqrhqd0embynr8vda9.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678468/textura/oqedhtdfun0htusj8uog.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678464/textura/yu6tjgdsm9ez4lucum9z.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678457/textura/kzvofpojxsv3j4xe1zef.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678463/textura/vhxbk3rm5ohpcjf66ptg.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678456/textura/a4uzifltwbwcmpdri89a.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678463/textura/e1dnlwxajayed89gixje.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678457/textura/mfpis3dpuuulobrbzbbm.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678456/textura/jugo4pzlkyidrgti1y6a.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678468/textura/asrrvytyirvm4abn9mk2.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678466/textura/hbgvkxbgree9ozwkhn4g.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678457/textura/trjyx2luzckkb9pugvam.jpg',
]


export default function LiveSlide() {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(null);
  };

  return (
    <div className="portfolio-multi">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Autoplay, Navigation]}
        className="mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {CollectiveImages.map((content, index) => {
          return (
            <SwiperSlide key={index}>
              <Button
                onClick={() => handleOpen(index)}
                style={{ color: "white" }}
              >
                <div className="overlay-text">
                  View More<span className="rotate45"> → </span>
                </div>
              </Button>
              <img
                src={content}
                alt={`Project ${index + 1}`}
              />
            </SwiperSlide>
          );
        })}
       <ThreeModal
          open={open}
          onClose={handleClose}
          currentIndex={currentIndex}
          projects={CollectiveImages}
        />
      </Swiper>
    </div>
  );
}


const ThreeModal = ({ open, onClose, currentIndex, projects }) => {
  useEffect(() => {
    // Disable body scroll when modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset"; // Re-enable body scroll when modal is closed
    }

    // Cleanup function to reset body overflow on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  if (!open) return null; // Don't render anything if not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className="close-button" onClick={onClose}>
          &times; {/* Close button */}
        </p>
        <div className="modal-body">
          <div
            style={{
              display: "flex",
              gap: "2%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {projects.map((project, id) => (
                <img
                  key={id}
                  src={project}
                  style={{
                    margin: "1%",
                    padding: "2%",
                    maxWidth: "70vw",
                    maxHeight: "70vh",
                    objectFit: "contain",
                  }}
                  alt={`Project ${id}`}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

