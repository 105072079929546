import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import {
  SectionHeading,
  SectionInnerHeading,
} from "../about/about.styles";
import service1 from "../../images/service1.jpg";
import service2 from "../../images/service2.jpg";
import service3 from "../../images/service3.jpg";
import { StyledServiceContentHeading, SectionWrapper, StyledServiceCard } from "./services.styles";
import './services.styles.css'; // Ensure to import the CSS file

const Services = () => {
  const services = [
    {
      id: 1,
      title: "Interior Design Consultation",
      description:
        "Our expert designers provide personalized consultations to understand your vision and needs, ensuring a tailored approach to your space.",
      imageSrc: 'https://res.cloudinary.com/dk7vwmblr/image/upload/v1731305598/textura/serviceimages/lytj6jjald1s8ckjyany.png',
    },
    {
      id: 2,
      title: "Space Planning",
      description:
        "We specialize in optimizing your space layout to enhance functionality and flow, creating an environment that works for you.",
      imageSrc: "https://res.cloudinary.com/dk7vwmblr/image/upload/v1731305607/textura/serviceimages/j5g0h7kqpkmckxcmbepo.png",
    },
    {
      id: 3,
      title: "Custom Furniture Design",
      description:
        "Our team crafts bespoke furniture pieces that perfectly fit your style and space, combining aesthetics with practicality.",
      imageSrc: 'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678577/textura/3d/gh85ofyhtw2vjsis1tu7.jpg',
    },
    {
      id: 4,
      title: "Color Consultation",
      description:
        "We help you choose the perfect color palette that reflects your personality and complements your space, creating a harmonious atmosphere.",
      imageSrc: service3,
    },
    {
      id: 5,
      title: "Lighting Design",
      description:
        "Our lighting design services enhance the mood and functionality of your space, using innovative solutions to create the perfect ambiance.",
      imageSrc: 'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678577/textura/3d/zcsk6eh3qnfsarehdywq.jpg',
    },
    {
      id: 6,
      title: "Project Management",
      description:
        "We oversee every aspect of your design project, ensuring timely execution and quality control from concept to completion.",
      imageSrc: service3,
    },
  ];

  return (
    <SectionWrapper id='serviceTextura'>
      <SectionHeading>Services</SectionHeading>
      <SectionInnerHeading marginBottom={20}>
        Creative Solutions By Professional Designers
      </SectionInnerHeading>
      <Grid container spacing={2}>
        {services.map((service, index) => (
          <Grid item key={service.id} xs={6} sm={6} md={4} data-aos="zoom-in-down" data-aos-duration="800">
            <StyledServiceCard>
              <CardMedia
                component="img"
                height="100%"
                image={service.imageSrc}
                alt="green iguana"
              />
              <CardContent>
                <StyledServiceContentHeading>
                  {service.title}
                </StyledServiceContentHeading>
                <Typography variant="body2" color="#d3bfa3" className="service-description"> {/* Added className for ellipsis effect */}
                  {service.description}
                </Typography>
              </CardContent>
            </StyledServiceCard>
          </Grid>
        ))}
      </Grid>
    </SectionWrapper>
  );
};

export default Services;
