import styled from "styled-components";

export const CounterWrapper = styled.div`
  padding: 6% 6% 6% 6%;
  @media (max-width: 768px) {
    padding: 2% 0; /* Set padding to 10% for mobile view */
  }
`;

export const StyledCounterHeading = styled.p`
  font-family: "Playfair Display", serif;
  margin: 0;
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 14px; /* Set font size to 14px for mobile view */
  }
`;

export const StyledCounterValue = styled.div`
  font-family: "Playfair Display", serif;
  margin: 0;
  font-size: 3rem;
  @media (max-width: 768px) {
    font-size: 1.5rem; /* Set font size to 1.5rem for mobile view */
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  place-content: center;
  text-align: center;
  @media (max-width: 768px) {
    gap: 10px; /* Set padding to 0 for mobile view */
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: -1rem;
  font-size: 5rem;
  svg{
    font-size: 5rem;
  }
  @media (max-width: 768px) {
  svg{
    font-size: 3rem; /* Set font size to 1rem for mobile view */
  }
  }
`;
