import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./multi.css";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import Button from "@mui/material/Button";
import CustomModal from "../modal/CustomModal";
// /3dProject/project2/image0.jpg
import "../modal/CustomModal.css"; // Import the CSS for styling

const projects = {
  0: [
    "/Portfolio/3dProject/project1/image0.jpg",
    "/Portfolio/3dProject/project1/image1.jpg",
    "/Portfolio/3dProject/project1/image2.jpg",
    "/Portfolio/3dProject/project1/image3.jpg",
    "/Portfolio/3dProject/project1/image4.jpg",
    "/Portfolio/3dProject/project1/image5.jpg",
  ],
  1: [
    "/Portfolio/3dProject/project2/image0.jpg",
    "/Portfolio/3dProject/project2/image1.jpg",
    "/Portfolio/3dProject/project2/image2.jpg",
    "/Portfolio/3dProject/project2/image3.jpg",
    "/Portfolio/3dProject/project2/image4.jpg",
    "/Portfolio/3dProject/project2/image5.jpg",
  ],
  2: [
    "/Portfolio/3dProject/project3/image0.jpg",
    "/Portfolio/3dProject/project3/image1.jpg",
    "/Portfolio/3dProject/project3/image2.jpg",
    "/Portfolio/3dProject/project3/image3.jpg",
    "/Portfolio/3dProject/project3/image4.jpg",
    "/Portfolio/3dProject/project3/image5.jpg",
  ],
};

const newProjects = [
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678578/textura/3d/jwnybrs0rgjeeqi1ey1t.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678577/textura/3d/dwwgcfvlnjrhoddaotyn.jpg',
  'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678577/textura/3d/zcsk6eh3qnfsarehdywq.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678578/textura/3d/hzuij5xcbtk0oqzezenp.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678529/textura/3d/cbazdgzbtxzfjexclbwh.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678579/textura/3d/xtgkoyk11yilz1d05fbt.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678527/textura/3d/ym74519j3k3ancjhegbk.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678528/textura/3d/lap73jd1stx8enkzjolm.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678577/textura/3d/gh85ofyhtw2vjsis1tu7.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678576/textura/3d/xmatoaveq03i0k1npup8.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678527/textura/3d/jfuultsvqifi0soqdbpj.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678527/textura/3d/yv1w0pxflrfwriyoyq0r.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678527/textura/3d/troifjm8msyxtnnqa1ei.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678577/textura/3d/cbxgibqvtp7xw9xx3ir1.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678578/textura/3d/cxji3qampnxshoy1coss.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678528/textura/3d/eh09h1fggzzdvqikilq2.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678527/textura/3d/dilkfahsihj3qvgxjjfh.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678527/textura/3d/hrchdqj4sb155qv0w3dg.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678529/textura/3d/p4l6qd527zy4re9inuob.jpg',
'https://res.cloudinary.com/dk7vwmblr/image/upload/v1729678578/textura/3d/vqq5oz2ak1ebbpyqoj8e.jpg'

]



export default function MultiSlideThree() {
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleOpen = (index) => {
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentIndex(null);
  };

  return (
    <div className="portfolio-multi">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Autoplay, Navigation]}
        className="mySwiper"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
      >
        {newProjects.map((content, index) => {
          return (
            <SwiperSlide key={index}>
              <Button
                onClick={() => handleOpen(index)}
                style={{ color: "white" }}
              >
                <div className="overlay-text">
                  View More<span className="rotate45"> → </span>
                </div>
              </Button>
              <img
                src={content}
                alt={`Project ${index + 1}`}
              />
            </SwiperSlide>
          );
        })}
        <ThreeModal
          open={open}
          onClose={handleClose}
          currentIndex={currentIndex}
          projects={newProjects}
        />
      </Swiper>
    </div>
  );
}



const ThreeModal = ({ open, onClose, currentIndex, projects }) => {
  useEffect(() => {
    // Disable body scroll when modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset"; // Re-enable body scroll when modal is closed
    }

    // Cleanup function to reset body overflow on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  if (!open) return null; // Don't render anything if not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className="close-button" onClick={onClose}>
          &times; {/* Close button */}
        </p>
        <div className="modal-body">
          <div
            style={{
              display: "flex",
              gap: "2%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {projects.map((project, id) => (
                <img
                  key={id}
                  src={project}
                  style={{
                    margin: "1%",
                    padding: "2%",
                    maxWidth: "70vw",
                    maxHeight: "70vh",
                    objectFit: "contain",
                  }}
                  alt={`Project ${id}`}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

