import React, { useEffect } from "react";
import "./CustomModal.css"; // Import the CSS for styling

const CustomModal = ({ open, onClose, currentIndex, projects }) => {
  useEffect(() => {
    // Disable body scroll when modal is open
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset"; // Re-enable body scroll when modal is closed
    }

    // Cleanup function to reset body overflow on unmount
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [open]);

  if (!open) return null; // Don't render anything if not open

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className="close-button" onClick={onClose}>
          &times; {/* Close button */}
        </p>
        <div className="modal-body">
          <div
            style={{
              display: "flex",
              gap: "2%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {currentIndex !== null &&
              projects[currentIndex].map((project, id) => (
                <img
                  key={id}
                  src={project}
                  style={{
                    margin: "1%",
                    padding: "2%",
                    maxWidth: "70vw",
                    maxHeight: "70vh",
                    objectFit: "contain",
                  }}
                  alt={`Project ${id}`}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
