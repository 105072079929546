import React, { useEffect, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  HamburgerIcon,
  IconWrapper,
  LogoImage,
  LogoText,
  MainIconWrapper,
  MobileMenu,
  MobileMenuItem,
  MobileSocialIcons,
  NavWrapper,
  StyledLiWrapper,
  StyledUlWrapper,
  TransparentNavbar,
} from "./navbar.styles";

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.pageYOffset > 0;
      setScrolling(isScrolling);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav>
      <NavWrapper>
        <TransparentNavbar scrolling={scrolling}>
          <LogoText href="/">
            <LogoImage src="https://res.cloudinary.com/dk7vwmblr/image/upload/v1730189204/textura/ygn5nzolzbazvzq5fqtw.jpg" alt="textura logo" />
          </LogoText>

          <StyledUlWrapper isMenuOpen={isMenuOpen}>
            <StyledLiWrapper>
              <a href="/">Home</a>
            </StyledLiWrapper>
            <StyledLiWrapper>
              <a href="#aboutTextura"> About us</a>
            </StyledLiWrapper>
            <StyledLiWrapper>
              <a href="#portfolioTextura">Portfolio</a>
            </StyledLiWrapper>
            <StyledLiWrapper>
              <a href="#serviceTextura">Services</a>
            </StyledLiWrapper>
            <StyledLiWrapper>
              <a href="#footerTextura">Contact</a>
            </StyledLiWrapper>
          </StyledUlWrapper>

          <MainIconWrapper>
            <IconWrapper>
              <FacebookIcon />
            </IconWrapper>
            <IconWrapper>
              <a href="https://www.instagram.com/textura_interior_" target="_blank">
                <InstagramIcon />
              </a>
            </IconWrapper>
            <IconWrapper>
              <a href="https://wa.me/919619493232" target="_blank">
                <WhatsAppIcon />
              </a>
            </IconWrapper>
          </MainIconWrapper>

          <HamburgerIcon onClick={handleMenuToggle}>&#9776;</HamburgerIcon>

          {isMenuOpen && (
            <MobileMenu>
              <MobileMenuItem href="/">Home</MobileMenuItem>
              <MobileMenuItem href="#aboutTextura">About us</MobileMenuItem>
              <MobileMenuItem href="#portfolioTextura">
                Portfolio
              </MobileMenuItem>
              <MobileMenuItem href="#serviceTextura">Services</MobileMenuItem>
              <MobileSocialIcons>
                <IconWrapper>
                  <FacebookIcon />
                </IconWrapper>
                <IconWrapper>
                  <a href="https://www.instagram.com/textura_interior_/" target="_blank">
                    <InstagramIcon />
                  </a>
                </IconWrapper>
                <IconWrapper>
                  <a href="https://wa.me/919619493232" target="_blank">
                    <WhatsAppIcon />
                  </a>
                </IconWrapper>
              </MobileSocialIcons>
            </MobileMenu>
          )}
        </TransparentNavbar>
      </NavWrapper>
    </nav>
  );
};

export default Navbar;
