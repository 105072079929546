import React, { useState, useEffect, useRef } from "react";
import {
  SectionHeading,
  SectionInnerHeading,
  SectionWrapper,
} from "../about/about.styles";
import NewTestimonial from "./NewTestimonial";

const Testimonial = () => {
  const [autoPlay, setAutoPlay] = useState(false);

  const cardDetails = [
    {
      id: 1,
      name: "John Doe",
      feedback:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.",
    },
    {
      id: 2,
      name: "Jane Smith",
      feedback: "Amazing work",
    },
    {
      id: 3,
      name: "Riddhi Parmar",
      feedback:
        "Duis vulputate commodo lectus, ac blandit elit tincidunt id. Sed rhoncus, tortor sed eleifend tristique, tortor mauris maximus dui, in imperdiet sapien urna sit amet libero.",
    },
    {
      id: 4,
      name: "Amisha Baria",
      feedback: "in imperdiet sapien urna sit amet libero.",
    },
    {
      id: 5,
      name: "Rajesh Parmar",
      feedback: "maximus dui, in imperdiet sapien urna sit amet libero.",
    },
    {
      id: 6,
      name: "Sumitra Baria",
      feedback:
        "Duis veleifend tristique, tortor mauris maximus dui, in imperdiet sapien urna sit amet libero.",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const visibleCards = 1;

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => Math.max(0, prevSlide - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      Math.min(cardDetails.length - visibleCards, prevSlide + 1)
    );
  };

  const autoPlayIntervalRef = useRef(null);

  const startAutoplay = () => {
    if (autoPlay) {
      autoPlayIntervalRef.current = setInterval(() => {
        nextSlide();
      }, 3000);
    }
  };

  useEffect(() => {
    startAutoplay();
    return () => {
      clearInterval(autoPlayIntervalRef.current);
    };
  }, [autoPlay]);

  const handleIndicatorClick = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    if (autoPlay && autoPlayIntervalRef.current) {
      clearInterval(autoPlayIntervalRef.current);
      startAutoplay();
    }
  }, [currentSlide]);

  return (
    <SectionWrapper>
      <SectionHeading center={true}>Success Stories</SectionHeading>
      <SectionInnerHeading marginBottom={20} center={true}>
        Discover the experiences of our satisfied clients
      </SectionInnerHeading>
      <NewTestimonial />
    </SectionWrapper>
  );
};

export default Testimonial;
