import React from "react";
import "./testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay, Navigation } from "swiper/modules";
export default function NewTestimonial() {
  return (
    <div className="testimonial-section">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        grabCursor={true}
        // autoplay={{
        //   delay: 3500,
        //   disableOnInteraction: false,
        // }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        navigation={false}
        modules={[Pagination, Autoplay, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="testimonial">
            <p className="testimonial-text">
              "I cannot express how happy I am with the transformation of my
              apartment! The design team listened carefully to all my
              preferences and created a space that is both elegant and
              functional. They managed to merge modern aesthetics with cozy
              elements, and the attention to detail was impeccable. Every guest
              who visits is blown away by the stylish touches in every corner. I
              highly recommend them for anyone looking to elevate their home!"
            </p>
            <div className="testimonial-info">
              <span className="name">Ghanshyam Variya</span>
              <span className="location"> Gujarat, IN</span>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="testimonial">
            <p className="testimonial-text">
              "Working with this interior design firm was a fantastic experience
              from start to finish. They took the time to understand my
              lifestyle and created a space that reflects my personality
              perfectly. From the selection of furniture to the clever use of
              natural light, everything was done with such creativity and care.
              I never imagined my home could look this beautiful and still be so
              practical for everyday living!"
            </p>
            <div className="testimonial-info">
              <span className="name">Shivang Kharoo</span>
              <span className="location"> Mumbai, IN</span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonial">
            <p className="testimonial-text">
              "This design team completely transformed our outdated house into a
              modern masterpiece. They took the time to understand our vision
              and brought it to life with unique ideas we never would have
              thought of. The open concept kitchen and living room are now my
              favorite spaces, filled with warm tones and sleek finishes. Their
              dedication to quality and passion for design truly shines through
              in every aspect of their work. We couldn't be happier!"
            </p>
            <div className="testimonial-info">
              <span className="name">Riddhi Parmar</span>
              <span className="location"> Gujarat, IN</span>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="testimonial">
            <p className="testimonial-text">
              "I’ve worked with several designers in the past, but this team was
              by far the best. They have a real eye for combining luxury with
              comfort. The entire process was seamless, from the initial
              consultation to the final reveal. They maximized the space in my
              small condo, making it feel much larger and more welcoming. Their
              creativity and professionalism exceeded my expectations. Highly
              recommend for anyone looking to redesign their home!"
            </p>
            <div className="testimonial-info">
              <span className="name">Harsh Tomar</span>
              <span className="location"> Mumbai, IN</span>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
